import React from 'react';
import RouterComponent from './Router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

library.add(fab, faPaperPlane);

function App() {
  return (
    <div className='wrapper'>
      <RouterComponent />
      <ToastContainer />
    </div>
  );
}

export default App;
