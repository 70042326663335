const githubUrl = 'https://github.com/codenaz';
export const gitHubProjects = [
  {
    title: 'React Paginator',
    description: 'A simple library for adding pagination to your React app',
    link: `${githubUrl}/react-paginator`
  },
  {
    title: 'Reveal',
    description: 'A simple React + Laravel boilerplate setup',
    link: `${githubUrl}/reveal`
  },
  {
    title: 'React Multiple Image Input',
    description:
      'A fancy component for selecting and cropping multiple images in a React app',
    link: `${githubUrl}/react-multiple-image-input`
  }
];

export const summitechProjects = [
  {
    title: 'Filmhouse Cinemas',
    description:
      'An online movie booking platform where users view filmhouse movies showtime and book tickets',
    link: 'https://filmhouseng.com',
    imageFileName: 'filmhouse-project_rmeypc.png'
  },
  {
    title: 'HealthPartners',
    description: "A website for healthpartners' HMO",
    link: 'https://healthpartnersng.org',
    imageFileName: 'hp-project_a6ixlf.png'
  },
  {
    title: 'QwikFx',
    description: 'A peer to peer currency exchange mobile application',
    link: 'https://qwikfx.com',
    imageFileName: 'qwikfx_zya8qg.png'
  }
];

export const freelanceProjects = [
  {
    title: '234Homes',
    description:
      'A web application for interior decoration vendors and designers to showcase and sell their work ',
    link: 'https://homes234react.herokuapp.com',
    imageFileName: '234homes-project_objvgd.png'
  }
];

export const personalProjects = [
  {
    title: 'Translate a tweet',
    description: 'A twitter bot that translates tweets into various languages',
    link: 'https://twitter.com/translateatweet',
    imageFileName: 'translate-project_znjwko.png'
  },
  {
    title: 'Mouau Hangouts',
    description:
      'A web application that creates an avenue for students to interact about their upcoming events',
    link: 'https://mouauhangouts.com',
    imageFileName: 'mouauhangouts.png'
  }
];

export const toolingProjects = [
  {
    title: 'Katacoda CLI',
    description:
      "A CLI tool that help trainers create content on Katacoda's learning platform",
    link: `https://www.npmjs.com/package/katacoda-cli`
  },
  {
    title: 'Katacoda VScode Extenstion',
    description:
      "A VScode extension that help trainers create content on Katacoda's learning platform",
    link: `#`
  }
];

export const articles = [
  {
    title: 'Using Custom Hooks in place of render props',
    link:
      'https://dev.to/emeka/using-custom-hooks-in-place-of-render-props-38mf'
  },
  {
    title: 'Use Context: A good reason to drop redux',
    link: 'https://dev.to/emeka/usecontext-a-good-reason-to-drop-redux-216l'
  },
  {
    title: 'Setup React + Laravel without laravel mix',
    link: 'https://dev.to/emeka/setup-react-laravel-without-laravel-mix-4437'
  },
  {
    title: 'Securing your express/Node.js API with firebase auth',
    link:
      'https://dev.to/emeka/securing-your-express-node-js-api-with-firebase-auth-4b5f'
  },
  {
    title: 'Handling async operations and promise values in javascript arrays',
    link: 'https://dev.to/emeka/my-array-made-me-a-promise-530g'
  },
  {
    title: 'State management in react using context',
    link:
      'https://dev.to/emeka/state-management-in-react-native-using-context-14no'
  }
];
