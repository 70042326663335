import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { GitHubComponent } from './components/GithubProjects';
import {
  gitHubProjects,
  summitechProjects,
  personalProjects,
  toolingProjects
} from './constants';
import { ApplicationProject } from './components/ApplicationProjects';
import { ToolingComponent } from './components/ToolingProjects';
import MetaData from './components/MetaData';

export default function Project() {
  return (
    <div className='col'>
      <MetaData
        title="Patrick Emeka's Projects"
        description='Projects by Patrick Emeka'
      />
      <div className='project'>
        <Accordion allowZeroExpanded allowMultipleExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Projects for Summitech</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className='app__projects'>
                {summitechProjects.map(
                  ({ title, description, link, imageFileName }, index) => (
                    <ApplicationProject
                      key={index}
                      index={index}
                      title={title}
                      description={description}
                      link={link}
                      imageFileName={imageFileName}
                    />
                  )
                )}
              </div>
              <div className='tooling__projects'>
                {toolingProjects.map(({ title, description, link }, index) => (
                  <ToolingComponent
                    key={index}
                    title={title}
                    description={description}
                    link={link}
                  />
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Personal Projects</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className='app__projects'>
                {personalProjects.map(
                  ({ title, description, link, imageFileName }, index) => (
                    <ApplicationProject
                      key={index}
                      index={index}
                      title={title}
                      description={description}
                      link={link}
                      imageFileName={imageFileName}
                    />
                  )
                )}
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Github Projects/Open Source
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className='github__projects'>
                {gitHubProjects.map(({ title, description, link }, index) => (
                  <GitHubComponent
                    key={index}
                    title={title}
                    description={description}
                    link={link}
                  />
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
