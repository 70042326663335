import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaData = props => (
  <Helmet>
    <title>{props.title}</title>
    <meta name='description' content={props.description} />
    <meta property='og:title' content={props.title} />
    <meta property='og:description' content={props.description} />
    <meta property='og:image' content={props.image} />
  </Helmet>
);

MetaData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};

export default MetaData;
