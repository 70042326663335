import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router-dom';
import history from './history';
import PropTypes from 'prop-types';
import Home from './Home';
import Project from './Project';
import Articles from './Articles';
import { Header } from './components/Header';
import { Footer } from './components/footer';
import About from './About';

function Scroll(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return props.children;
}

Scroll.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const ScrollToTop = withRouter(Scroll);

function RouterComponent() {
  return (
    <Router history={history}>
      <ScrollToTop>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/projects' component={Project} />
          <Route exact path='/articles' component={Articles} />
          <Route exact path='/about' component={About} />
        </Switch>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default RouterComponent;
