import React from 'react';
import PropTypes from 'prop-types';

export function ToolingComponent(props) {
  return (
    <div className='single'>
      <h4 className='project__title'>
        <a target='_blank' rel='noopener noreferrer' href={props.link}>
          {props.title}
        </a>
      </h4>
      <p className='project__description'>{props.description}</p>
    </div>
  );
}

ToolingComponent.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};
