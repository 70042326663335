import React from 'react';
import PropTypes from 'prop-types';

export function ApplicationProject(props) {
  return (
    <div className='single'>
      <div
        className='project__image row-9'
        style={{ order: props.index % 2 ? 0 : 1 }}
      >
        <picture>
          <source
            media='(min-width: 1280px)'
            sizes='64vw'
            srcSet={`https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_70,w_1280/${
              props.imageFileName
            } 1280w`}
          />
          <source
            media='(min-width: 1024px)'
            sizes='64vw'
            srcSet={`https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_70,w_1024/${
              props.imageFileName
            } 1024w`}
          />
          <source
            media='(min-width: 768px)'
            sizes='64vw'
            srcSet={`https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_70,w_768/${
              props.imageFileName
            } 768w`}
          />
          <source
            sizes='92vw'
            srcSet={`https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_70,w_512/${
              props.imageFileName
            } 512w`}
          />
          <img
            sizes='(min-width: 768px) 96vw, 92vw'
            srcSet={`https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_70,w_768/${
              props.imageFileName
            }`}
            alt='filmhouse_image'
          />
        </picture>
      </div>
      <div
        className='project__description row-3'
        style={{ order: props.index % 2 ? 1 : 0 }}
      >
        <h3 className='title'>
          <a target='_blank' rel='noopener noreferrer' href={props.link}>
            {props.title}
          </a>
        </h3>
        <p className='description'>{props.description}</p>
      </div>
    </div>
  );
}

ApplicationProject.propTypes = {
  link: PropTypes.string,
  imageFileName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  index: PropTypes.number
};
