import React from 'react';
import { articles } from './constants';
import MetaData from './components/MetaData';

export default function Articles() {
  return (
    <div className='articles col'>
      <MetaData
        title="Patrick Emeka's Articles"
        description='Articles written by Patrick Emeka'
      />
      <h2 className='articles__heading'>
        Here are some things i have written when i thought i had an idea or two
      </h2>
      <ul className='article__list col'>
        {articles.map((article, index) => (
          <li key={index}>
            <a href={article.link} target='_blank' rel='noopener noreferrer'>
              {article.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
