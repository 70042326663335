import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import Spinner from 'react-spinkit';

export default function Contact() {
  const initialForm = {
    email: '',
    name: '',
    message: ''
  };
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    emailjs.init('user_Li0DpcXKpvDEIYTyriqOS');
  }, []);

  function handleChange(e) {
    e.preventDefault();
    const data = { ...form };
    const name = e.target.name;
    data[name] = e.target.value;
    setForm(data);
  }

  function validate() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!form.email) {
      toast.error('Your email is required');
      return false;
    }
    if (!re.test(form.email)) {
      toast.error('email is invalid');
      return false;
    }
    if (!form.name) {
      toast.error('your name is required');
      return false;
    }
    if (!form.message) {
      toast.error('A message is required');
      return false;
    }
    return true;
  }

  function onSubmit(e) {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    const emailData = {
      from_name: form.name,
      from_email: form.email,
      message_content: form.message
    };
    setLoading(true);
    emailjs
      .send('gmail', 'template_Bmq6Pdno', emailData)
      .then(
        () => toast.success('Email sent successfully') && setForm(initialForm)
      )
      .catch(() => toast.error('could not send your email, please retry'))
      .finally(() => setLoading(false));
  }
  return (
    <section className='contact-form'>
      <h1 className='contact__caption'>Say Hello</h1>
      <form>
        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <input
            id='email'
            name='email'
            onChange={handleChange}
            value={form.email}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='name'>Name</label>
          <input
            id='name'
            name='name'
            onChange={handleChange}
            value={form.name}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='message'>Message</label>
          <textarea
            id='message'
            name='message'
            onChange={handleChange}
            value={form.message}
          />
        </div>
        <div className='form-group row'>
          <button
            className='btn btn-sm'
            type='button'
            onClick={onSubmit}
            disabled={loading}
          >
            Send <FontAwesomeIcon icon='paper-plane' size='xs' />
          </button>
          {loading && <Spinner name='double-bounce' color='#ffffff' />}
        </div>
      </form>
    </section>
  );
}
