import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'react-router-dom';

export const Footer = withRouter(() => {
  return (
    <footer className='footer'>
      <ul className='nav-items'>
        <li>
          <Link to='/projects'>Project</Link>
        </li>
        <li>
          <Link to='/articles'>Articles</Link>
        </li>
        <li>
          <Link to='/about'>About</Link>
        </li>
      </ul>
      <div className='other-links'>
        <ul className='nav-items'>
          <li>
            <a
              aria-label='github profile'
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/codenaz'
            >
              <FontAwesomeIcon icon={['fab', 'github']} size='3x' />
            </a>
          </li>
          <li>
            <a
              aria-label='Dev.to blog'
              target='_blank'
              rel='noopener noreferrer'
              href='https://dev.to/emeka'
            >
              <FontAwesomeIcon icon={['fab', 'dev']} size='3x' />
            </a>
          </li>
          <li>
            <a
              aria-label='Twitter profile'
              target='_blank'
              rel='noopener noreferrer'
              href='https://twitter.com/codenaz'
            >
              <FontAwesomeIcon icon={['fab', 'twitter']} size='3x' />
            </a>
          </li>
          <li>
            <a
              aria-label='Instagram Profile'
              target='_blank'
              rel='noopener noreferrer'
              href='https://instagram.com/code_naz'
            >
              <FontAwesomeIcon icon={['fab', 'instagram']} size='3x' />
            </a>
          </li>
        </ul>
      </div>
      <p>CopyRight &#169; Nwakwoke Patrick {new Date().getFullYear()}</p>
    </footer>
  );
});
