import React from 'react';
import Contact from './components/Contact';
import MetaData from './components/MetaData';

export default function About() {
  return (
    <div className='col about'>
      <MetaData
        title='About Patrick Emeka'
        description='A description of Patrick Eemeka'
      />
      <section className='about__hero'>
        <picture>
          <source
            media='(min-width: 1280px)'
            sizes='96vw'
            srcSet='https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_100,w_1280/IMG_5689_wl0cyx.jpg 1280w'
          />
          <source
            media='(min-width: 1024px)'
            sizes='96vw'
            srcSet='https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_100,w_1024/IMG_5689_wl0cyx.jpg 1024w'
          />
          <source
            media='(min-width: 768px)'
            sizes='96vw'
            srcSet='https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_100,w_768/IMG_5689_wl0cyx.jpg 768w'
          />
          <source
            sizes='92vw'
            srcSet='https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_70,w_512/IMG_5689_wl0cyx.jpg 512w'
          />
          <img
            sizes='(min-width: 768px) 96vw, 92vw'
            srcSet='https://res.cloudinary.com/dcl2ktkry/image/upload/f_auto,q_70,w_768/IMG_5689_wl0cyx.jpg'
            alt="patrick's_image"
          />
        </picture>
      </section>
      <div className='about__content'>
        <p>
          Nwakwoke Patrick is a software engineer, working with React, React
          Native, Laravel and Express Js as his primary stack. He currently
          works with Andela. Due to his vast knowledge working with javascript
          and ability to adapt easily to building in various javascript
          environments he has built software beyond just mobile and web apps. He
          has also led a number of development teams in the course of his career
        </p>

        <p>He writes when he feels up to it mostly on dev.to</p>

        <p>
          He got his B.Eng in computer engineering from Micheal Okpara
          University in 2017 with a second class upper division
        </p>
        <p>
          He currently has some good years of programming experience from when
          he wrote his first line of code back in 2013, and professional
          experience since the start of his internship at intelligent campaign
          hub in 2016. He then went ahead to join summitech late in 2017 soon
          after graduating from University. In 2019, he joined Andela as a
          software engineer.
        </p>
        <p>
          If you would like to reach out to Patrick for any reason at all, feel
          free to simply fill out this contact form below. Looking forward to
          hearing from you
        </p>
      </div>
      <Contact />
    </div>
  );
}
