import React, { useEffect, useState } from 'react';
import Typist from 'react-typist';
import Contact from './components/Contact';
import MetaData from './components/MetaData';

export default function Home() {
  const [skill, setSkill] = useState('');
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    let count = 0;
    const skills = ['WEB APPS', 'MOBILE APPS', 'BOTS', 'APIS'];
    const interval = setInterval(() => {
      count++;
      const currentSkill = skills[count % skills.length];
      setSkill(currentSkill);
      setTyping(false);
      setTyping(true);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <MetaData
        title="Patrick Emeka's portfolio"
        description="A web app that showcase Patrick Emeka's works"
      />
      <section className='hero'>
        <h1 className='hero__caption'>I Build </h1>
        {typing ? (
          <Typist avgTypingDelay={100} cursor={{ show: false }}>
            <h1 className='hero__skill'> {skill}</h1>
          </Typist>
        ) : (
          ''
        )}
      </section>
      <Contact />
    </>
  );
}
