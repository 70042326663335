import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

function HeaderComponent() {
  return (
    <nav className='header'>
      <h2>
        <Link to='/'>PATRICK EMEKA</Link>
      </h2>
      <ul className='nav-items'>
        <li>
          <Link to='/projects'>Project</Link>
        </li>
        <li>
          <Link to='/articles'>Articles</Link>
        </li>
        <li>
          <Link to='/about'>About</Link>
        </li>
      </ul>
    </nav>
  );
}

export const Header = withRouter(HeaderComponent);
